import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OwnerType, VehicleModel } from 'src/app/models/vehicle';
import { VehicleService } from 'src/app/services/vehicle.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@Component({
  selector: 'app-new-vehicle',
  templateUrl: './new-vehicle.component.html',
  styleUrls: ['./new-vehicle.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewVehicleComponent implements OnInit {

  newVehicle: VehicleModel = new VehicleModel();
  public ownerTypeCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewVehicleComponent>,
    private vehicleService: VehicleService,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.ownerTypeCtrl.setValue(OwnerType.Franchise.toString());
  }

  saveNewVehicle() {
    if (!this.newVehicle.licensePlate) {
      this.toastr.error('Plaka bilgisi gereklidir.', 'Araç Ekleme');
      return;
    }
    if (!this.newVehicle.year) {
      this.toastr.error('Yıl bilgisi gereklidir.', 'Araç Ekleme');
      return;
    }
    if (!this.newVehicle.brand) {
      this.toastr.error('Marka bilgisi gereklidir.', 'Araç Ekleme');
      return;
    }

    this.newVehicle.ownerType = Number(this.ownerTypeCtrl.value);

    this.vehicleService.post(this.newVehicle).subscribe(
      res => {
        this.toastr.success(`${this.newVehicle.licensePlate} plakalı araç eklendi.`, 'Araç Ekleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Araç Ekleme');
        this.dialogRef.close({ success: false, result: err });
      }
    );
  }
}
