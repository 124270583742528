import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-authorizedlayout',
  templateUrl: './authorizedlayout.component.html',
  styleUrls: ['./authorizedlayout.component.css']
})
export class AuthorizedLayoutComponent implements OnInit {


  constructor(public authService: AuthService) {

  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  ngOnInit(): void {
    console.info(this.authService.autorizedUser);
  }

}
