import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
  })
export class IsAuthenticatedGuard implements CanActivate {
    
    constructor(private authService:AuthService) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.isLoggedIn.pipe(
            tap((isloggedIn)=>{
                if (!isloggedIn) {
                    this.authService.logout();
                }
            })
        )
    }
  
}
