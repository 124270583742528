import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ClientOrganizationModel } from 'src/app/models/client-organization.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientOrganizationService } from 'src/app/services/client-organization.service';
import { NewClientOrganizationComponent } from './new-client-organization/new-client-organization.component';

@Component({
  selector: 'app-client-organization',
  templateUrl: './client-organization.component.html',
  styleUrls: ['./client-organization.component.css']
})

export class ClientOrganizationComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  positionPage: number = 0;
  
  constructor(public clientOrganizationService: ClientOrganizationService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    let companyId=this.authService.autorizedUser.company;
    this.clientOrganizationService.getAll(companyId)
      .toPromise()
      .then(res => {
        console.log(res)
        this.clientOrganizationService.list = res as ClientOrganizationModel[];
        this.dataSource = new MatTableDataSource(this.clientOrganizationService.list);
      });
  }

  openNewClientDialog() {
    let dialogRef=this.dialog.open(NewClientOrganizationComponent, {
      data: {},
      minWidth:'50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`); 
      console.log(result); 
      if (result.success) {
        this.clientOrganizationService.list.push(result.result);
        this.dataSource = new MatTableDataSource(this.clientOrganizationService.list);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}


