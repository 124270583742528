
<div class="row">
    <!-- column -->
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">Müşteri Listesi</span>
                <button type="button" 
                (click)="openNewClientDialog()"
                class="btn btn-sm btn-outline-secondary py-0">
                    <span>Yeni Müşteri</span>
                </button>
            </div>
            <div class="card-body p-0">
                <div class="client-search-wrapper">
                    <mat-form-field appearance="fill" subscriptSizing="dynamic">
                        <mat-label>Müşteri Ara</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Müşteri adı..." #input>
                    </mat-form-field>
                </div>
                <section class="example-container mat-elevation-z8" tabindex="0">

                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let row; let i=index;"> {{(positionPage)+(i+1)}} </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> İsim </th>
                            <td mat-cell *matCellDef="let element">
                                <a [routerLink]="['/u/client', element.id]" class="btn text-dark w-100 text-start"> <span>{{element.name}} </span></a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center p-3" [attr.colspan]="displayedColumns.length">Müşteri yok. <span *ngIf="input.value">"{{input.value}}"</span></td>
                        </tr>
                    </table>
                </section>
            </div>
        </div>
    </div>
</div>