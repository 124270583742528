import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StopModel } from 'src/app/models/stop';
import { StopService } from 'src/app/services/stop.service';

@Component({
  selector: 'app-new-stop',
  templateUrl: './new-stop.component.html',
  styleUrls: ['./new-stop.component.css']
})
export class NewStopComponent implements OnInit {
  newStop: StopModel = new StopModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: number,
    public dialogRef: MatDialogRef<NewStopComponent>,
    private stopService: StopService,
    private toastr: ToastrService) {

  }

  ngOnInit() {
  }

  saveNewStop() {
    if (!this.newStop.name) {
      this.toastr.error('Durak isim bilgisi gereklidir.', 'Durak Ekleme');
      return;
    }
    
    this.stopService.post(this.newStop,this.data).subscribe(
      res => {
        this.toastr.success(`Durak eklendi.`, 'Durak Ekleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        console.log(err.error);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
          this.toastr.error(errors, 'Durak Ekleme');
        // if (err.status===400) {
        //   this.toastr.error("Durak eklenemedi. Daha sonra tekrar deneyiniz.", 'Durak Ekleme');
        // }
        // else{
        //   let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        //   this.toastr.error(errors, 'Durak Ekleme');
        //   //this.dialogRef.close({ success: false, result: err });
        // }
      }
    ); 
  }
}
