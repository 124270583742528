import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DriverModel } from 'src/app/models/driver';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-new-driver',
  templateUrl: './new-driver.component.html',
  styleUrls: ['./new-driver.component.css']
})
export class NewDriverComponent implements OnInit {

  newDriver: DriverModel = new DriverModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DriverModel,
  public dialogRef: MatDialogRef<NewDriverComponent>,
  private driverService:DriverService,
  private toastr: ToastrService){

  }
  
  ngOnInit() {
  }

  saveNewDriver() {
    if (!this.newDriver.firstName) {
      this.toastr.error('İsim bilgisi gereklidir.', 'Sürücü Ekleme');
      return;
    }
    if (!this.newDriver.lastName) {
      this.toastr.error('Soyisim bilgisi gereklidir.', 'Sürücü Ekleme');
      return;
    }
    if (!this.newDriver.phoneNumber) {
      this.toastr.error('Telefon bilgisi gereklidir.', 'Sürücü Ekleme');
      return;
    }
    if (!this.newDriver.address) {
      this.toastr.error('Adres bilgisi gereklidir.', 'Sürücü Ekleme');
      return;
    }
    

    this.driverService.post(this.newDriver).subscribe(
      res => {
        this.toastr.success(`Sürücü bilgileri değiştirildi.`, 'Sürücü Ekleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Sürücü Ekleme');
        this.dialogRef.close({ success: false, result: err });
      }
    ); 
  }
}
