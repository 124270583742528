import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private authService: AuthService,
    private toastr: ToastrService,
    private router: Router) {

    this.authService.isLoggedIn.pipe(
      tap((isloggedIn) => {
        if (isloggedIn) {
          this.router.navigateByUrl('/u/dashboard');
        }
      })
    )


  }
  ngOnInit(): void {
    this.form.controls['username'].setValue("ali")
    this.form.controls['password'].setValue("Password@1")
  }

  submit() {
    if (!this.form.valid) {
      this.error = "Kullanıcı adı veya şifre hatalı."
      this.toastr.error(this.error, 'Giriş');
      return;
    }
    let data = {
      userName: this.form.controls['username'].value,
      password: this.form.controls['password'].value
    };

    this.authService.login(data).subscribe(
      res => {
        // if (this.authService.hasRole('RegionalSalesOfficer'))
        //   this.router.navigateByUrl('/u/sales/store');
        // else if (this.authService.hasRole('WarehouseAndDispatchingSupervisor'))
        //   this.router.navigateByUrl('/os/dashboard');
        // else
        this.router.navigateByUrl('/u/dashboard');
      },
      err => {
        console.log(err)
        let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' ')
          : Object.values([err.message]).join(' ')
          : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Giriş');
      }
    );

  }

  @Input()
  error!: string | null;

  @Output() submitEM = new EventEmitter();
}
