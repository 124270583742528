import { Component } from '@angular/core';

@Component({
  selector: 'app-server-company',
  templateUrl: './server-company.component.html',
  styleUrls: ['./server-company.component.css']
})
export class ServerCompanyComponent {

}
