import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { UserModel } from "../models/user";
import { PasswordChangeModel } from "../models/password-change-model";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/identity-api/user";

  list: UserModel[] = [];

  getAll() {
    return this.httpClient.get(`${this.baseURL}/all`);
  }

  getByCompanyId(companyId: number) {
    return this.httpClient.get(`${this.baseURL}/company/${companyId}`);
  }

  getById(id: string) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }

  refreshListOfAll() {
    this.getAll()
      .toPromise()
      .then(res => this.list = res as UserModel[]);
  }

  refreshListOfByCompany(companyId: number) {
    this.getByCompanyId(companyId)
      .toPromise()
      .then(res => this.list = res as UserModel[]);
  }

  postUser(data: UserModel) {
    return this.httpClient.post(`${this.configurationService.api_uri}/user/create`, data);
  }

  postCompanyUser(data: UserModel) {
    return this.httpClient.post(`${this.baseURL}/company`, data);
  }

  putUserDetail(data: UserModel) {
    return this.httpClient.put(`${this.baseURL}/update-detail/${data.id}`, data);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  userModel: UserModel = new UserModel();
  usersInRole: UserModel[];
  passwordChange: PasswordChangeModel = new PasswordChangeModel();

  getProfile(id: string) {
    return this.httpClient.get(`${this.baseURL}/${id}`)
      .toPromise()
      .then(res => {
        this.userModel = res as UserModel;
      });
  }

  getUserDetail(id: string) {
    return this.httpClient.get(`${this.baseURL}/${id}`);
  }

  getUsersInRole(roleName: string) {
    return this.httpClient.get(`${this.baseURL}/usersInRole/${roleName}`)
      .toPromise()
      .then(res => {
        this.usersInRole = res as UserModel[];
      });
  }


  putProfile() {
    return this.httpClient.put(`${this.baseURL}/${this.userModel.id}`, this.userModel);
  }

  deleteProfile(id: string) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

}