export class DriverModel {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: number;
    companyId: number;
    companyName: string;
}


export class DriverCountsModel{
    total:number;
    passiveDriver:number;
}