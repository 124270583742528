import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { CompanyModel } from '../models/server-company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/server-company-api/company";

  list: CompanyModel[] = [];

  getAll() {
    return this.httpClient.get(`${this.baseURL}`);
  }

  getById(id:number) {
    return this.httpClient.get(`${this.baseURL}/${id}`);
  }


  refreshList() {
    this.getAll()
      .toPromise()
      .then(res => this.list = res as CompanyModel[]);
  }

}
