import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public saveToken(value:string,key:string):void{
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key,value);
  }

  public getToken(key:string):string|null{
    return window.localStorage.getItem(key);
  }

  public removeToken(key:string){
    window.localStorage.removeItem(key);
  }

  public removeAll(){
    window.localStorage.clear();
  }
}
