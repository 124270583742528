import { MatPaginatorIntl } from '@angular/material/paginator';

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `Toplam ${length} kayıttan 0 kayıt gösteriliyor`;
    }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
  
    return `Toplam ${length} kayıttan ${startIndex + 1} - ${endIndex} kayıt gösteriliyor`;
  };

export function CustomPaginatorConfiguration() {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Sayfada gösterilen kayıt sayısı: ';
    customPaginatorIntl.firstPageLabel = 'İlk Sayfa';
    customPaginatorIntl.lastPageLabel = 'Son Sayfa';
    customPaginatorIntl.nextPageLabel = 'Sonraki Sayfa';
    customPaginatorIntl.previousPageLabel = 'Önceki Sayfa'; 
    customPaginatorIntl.getRangeLabel = dutchRangeLabel;

    return customPaginatorIntl;
}