<h2 mat-dialog-title>Kullanıcı Bilgileri</h2>

<div mat-dialog-content>
    <div class="container">
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>Kullanıcı Adı</mat-label>
                        <input matInput placeholder="Kullanıcı adı..." name="name" #name="ngModel"
                            [(ngModel)]="user.userName" required [class.invalid]="name.invalid&&name.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>E-Posta</mat-label>
                        <input matInput placeholder="E-posta adresi..." name="email" #email="ngModel"
                            [(ngModel)]="user.email" required [class.invalid]="email.invalid&&email.touched">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>İsim</mat-label>
                        <input matInput placeholder="İsim..." name="firstName" #firstName="ngModel"
                            [(ngModel)]="user.firstName" required
                            [class.invalid]="firstName.invalid&&firstName.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>Soyisim</mat-label>
                        <input matInput placeholder="Soyisim..." name="lastName" #lastName="ngModel"
                            [(ngModel)]="user.lastName" required [class.invalid]="lastName.invalid&&lastName.touched">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill" class="role-select-wrapper">
                        <mat-label>Yetki</mat-label>
                        <mat-select [formControl]="roleCtrl" placeholder="Yetki seçiniz" class="role-select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="roleFilterCtrl" placeholderLabel="Ara"
                                    noEntriesFoundLabel="Yetki bulunamadı." [clearSearchInput]=false>
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let role of filteredRoles" [value]="role">
                                <span class="text-muted">{{role.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="company-select-wrapper">
                        <mat-label>Firma</mat-label>
                        <mat-select [formControl]="companyCtrl" placeholder="Firma seçiniz" class="company-select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="companyFilterCtrl" placeholderLabel="Ara"
                                    noEntriesFoundLabel="Firma bulunamadı." [clearSearchInput]=false>
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let company of filteredCompanies" [value]="company">
                                <span class="text-muted">{{company.name}}</span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <div class="dialog-actions">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveNewUser()">Kaydet</button>
        <button mat-button mat-dialog-close class="btn btn-sm btn-dark text-white">Kapat</button>
    </div>
</div>