import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, pluck } from 'rxjs';
import { VehicleModel } from 'src/app/models/vehicle';
import { AuthService } from 'src/app/services/auth.service';
import { VehicleDriverService } from 'src/app/services/vehicle-driver.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { EditVehicleComponent } from './edit-vehicle/edit-vehicle.component';
import { NewVehicleComponent } from './new-vehicle/new-vehicle.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {
  displayedColumns: string[] = ['position', 'licenseplate', 'brand', 'year','seatingCapacity','ownerType','actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  positionPage: number = 0;
  selection = new SelectionModel<VehicleModel>(false, []);

  constructor(public vehicleService: VehicleService,
    public vehicleDriverService: VehicleDriverService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
    let companyId = this.authService.autorizedUser.company;
    this.vehicleService.getAll(companyId)
      .toPromise()
      .then(res => {
        console.log(res)
        this.vehicleService.list = res as VehicleModel[];
        this.dataSource = new MatTableDataSource(this.vehicleService.list);
      });

    this.selection.changed
      .pipe(pluck('source', 'selected'))
      .subscribe((selectedList) => {
        console.log('selected driver')
        console.log(selectedList)
        this.vehicleDriverService.selectedVehicle=this.selection.selected[0];
        if(this.selection.selected.length>0){
          this.vehicleDriverService.selectedVehicleIsActive=this.vehicleDriverService.list
          .some(x=>x.vehicle.id==this.selection.selected[0].id)
        }
      });
  }

  newVehicle(){
    let dialogRef=this.dialog.open(NewVehicleComponent, {
      data: {},
      minWidth:'50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.success) {
        this.vehicleService.list.push(result.result);
        this.dataSource = new MatTableDataSource(this.vehicleService.list);
      }
    });
  }

  editVehicle(element:VehicleModel){
    let dialogRef=this.dialog.open(EditVehicleComponent, {
      data: element,
      minWidth:'50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.success) {
        let id=result.result.id;
        let curr=this.vehicleService.list.find(x=>x.id==id);
        console.log(curr)
        curr.licensePlate=result.result.licensePlate;
        curr.brand=result.result.brand;
        curr.model=result.result.model;
        curr.year=result.result.year;
        curr.seatingCapacity=result.result.seatingCapacity;
        curr.ownerType=result.result.ownerType;
        console.log(curr)
      }
    });
  }

  deleteVehicle(element:VehicleModel){
    let options = {
      title: `Aracı istediğinizden emin misiniz?`,
      confirmLabel: 'Evet',
      declineLabel: 'Hayır'
    };
    this.ngxBootstrapConfirmService.confirm(options)
      .then((confirmRes: boolean) => {
        if (confirmRes) {

          this.vehicleService.delete(element.id).subscribe(
            res => {
              this.vehicleService.list=this.vehicleService.list.filter(x=>x.id != element.id);
              this.dataSource = new MatTableDataSource(this.vehicleService.list);
              this.toastr.success(`${element.licensePlate} plakalı araç silindi.`, 'Araç Silme');
            },
            err => {
              let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' ') : "Beklenmedik Hata!" : Object.values(err.errors).join(' ');
              this.toastr.error(errors, 'Araç Silme');
            }
          );
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
