import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientOrganizationModel } from '../models/client-organization.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClientOrganizationService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/client-organization-api/organization";

  list: ClientOrganizationModel[] = [];

  getAll(companyId:number) {
    return this.httpClient.get(`${this.baseURL}/company/${companyId}`);
  }

  getById(id:number) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }


  getCounts(companyId:number) {
    return this.httpClient.get(`${this.baseURL}/organizationcount/company/${companyId}`);
  }

  refreshList(companyId:number) {
    this.getAll(companyId)
      .toPromise()
      .then(res => this.list = res as ClientOrganizationModel[]);
  }

  ///organization/create
  post(data: any) {
    let newData = {
      clientOrganizationName: data.name,
      userName: data.userName
    };
    return this.httpClient.post(this.configurationService.api_uri +'/organization/create', newData);
  }
}
