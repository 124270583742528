

<h2 mat-dialog-title>Sürücü Bilgilerini Düzenle</h2>

<div mat-dialog-content>
    <div class="container">
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="row mb-1">
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>İsim</mat-label>
                        <input matInput placeholder="İsmi giriniz" 
                        name="firstName" #firstName="ngModel" [(ngModel)]="driver.firstName" 
                        required [class.invalid]="firstName.invalid&&firstName.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Soyisim</mat-label>
                        <input matInput placeholder="Soyismi giriniz" 
                        name="lastName" #lastName="ngModel" [(ngModel)]="driver.lastName" 
                        required [class.invalid]="lastName.invalid&&lastName.touched">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Telefon</mat-label>
                        <input matInput placeholder="Telefon giriniz" 
                        name="phoneNumber" #phoneNumber="ngModel" [(ngModel)]="driver.phoneNumber" 
                        required [class.invalid]="phoneNumber.invalid&&phoneNumber.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Adres</mat-label>
                        <input matInput placeholder="Adres giriniz" 
                        name="address" #address="ngModel" [(ngModel)]="driver.address" 
                        required [class.invalid]="address.invalid&&address.touched">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <div class="dialog-actions">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveDriver()">Kaydet</button>
        <button mat-button mat-dialog-close class="btn btn-sm btn-dark text-white">Kapat</button>
    </div>
</div> 