import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleModel } from 'src/app/models/role-model';
import { CompanyModel } from 'src/app/models/server-company';
import { UserModel } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { RoleService } from 'src/app/services/role-service.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user: UserModel = new UserModel();

  public companyCtrl: UntypedFormControl = new UntypedFormControl();
  public companyFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCompanies: CompanyModel[] = [];

  public roleCtrl: UntypedFormControl = new UntypedFormControl();
  public roleFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredRoles: RoleModel[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: UserModel,
    public dialogRef: MatDialogRef<EditUserComponent>,
    private authService: AuthService,
    public userService: UserService,
    public roleService: RoleService,
    public companyService: CompanyService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.user=Object.assign({},this.data);
    console.log(this.user)

    this.roleFilterCtrl.valueChanges
      .pipe()
      .subscribe(() => {
        this.filterRoles();
      });

    this.roleService.getAll()
      .toPromise()
      .then(res => {
        console.log(res)
        this.roleService.list = res as RoleModel[];
        this.filteredRoles = this.roleService.list
          .sort((a, b) => (a.name.trToEn().trToLower() > b.name.trToEn().trToLower()) ? 1 : -1);
      });

    this.companyFilterCtrl.valueChanges
      .pipe()
      .subscribe(() => {
        this.filterCompanies();
      });

    this.companyService.getAll()
      .toPromise()
      .then(res => {
        console.log(res)
        this.companyService.list = res as CompanyModel[];
        this.filteredCompanies = this.companyService.list
          .sort((a, b) => (a.name.trToEn().trToLower() > b.name.trToEn().trToLower()) ? 1 : -1);
      });
  }

  compareRole(t1: RoleModel, t2: RoleModel): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }

  filterRoles() {
    if (!this.roleService.list) {
      return;
    }
    let search = this.roleFilterCtrl.value;
    if (!search) {
      this.filteredRoles = this.roleService.list;
      return;
    } else {
      search = search.trToLower().trToEn();
    }

    this.filteredRoles = this.roleService.list.filter(x => {
      return ((x.name.trToLower().trToEn().indexOf(search) > -1))
    });
  }

  filterCompanies() {
    if (!this.companyService.list) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies = this.companyService.list;
      return;
    } else {
      search = search.trToLower().trToEn();
    }

    this.filteredCompanies = this.companyService.list.filter(x => {
      return ((x.name.trToLower().trToEn().indexOf(search) > -1))
    });
  }

  saveNewUser() {
    console.log(this.user)
    if (!this.user?.userName) {
      this.toastr.error('Kullanıcı ismi gereklidir.', 'Kullanıcı Düzenleme');
      return;
    }
    if (!this.user?.email) {
      this.toastr.error('E-posta adresi gereklidir.', 'Kullanıcı Düzenleme');
      return;
    }
    if (!this.user?.firstName) {
      this.toastr.error('İsim gereklidir.', 'Kullanıcı Düzenleme');
      return;
    }
    if (!this.user?.lastName) {
      this.toastr.error('Soyisim gereklidir.', 'Kullanıcı Düzenleme');
      return;
    }
    if (!this.roleCtrl.value) {
      this.toastr.error('Yetki seçmelisiniz', 'Kullanıcı Düzenleme');
    }
    if (!this.companyCtrl.value) {
      this.toastr.error('Firma seçmelisiniz', 'Kullanıcı Düzenleme');
    }

    this.user.roleId=this.roleCtrl.value.id;
    this.user.companyId=this.companyCtrl.value.id;

    this.userService.putUserDetail(this.user).subscribe(
      res => {
        this.toastr.success(`${this.user.firstName} ${this.user.lastName} eklendi.`, 'Kullanıcı Düzenleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Kullanıcı Düzenleme');
        this.dialogRef.close({ success: false, result: err });
      }
    );
  }
}

