import { Component } from '@angular/core';

@Component({
  selector: 'app-shuttle-route',
  templateUrl: './shuttle-route.component.html',
  styleUrls: ['./shuttle-route.component.css']
})
export class ShuttleRouteComponent {

}
