import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class HasRoleGuard implements CanActivate{
    constructor(private authService: AuthService,
        private router:Router) { }
    
      canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const isAutorized = route.data.roles.some(r=> this.authService.autorizedUser?.role.split(',').includes(r))
    
        if (!isAutorized) {
          //window.alert("Role: "+route.data.role+" not found.");
          this.authService.logout();
          // or unautorized page
        }
    
        return isAutorized;
      }
}