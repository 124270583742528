<div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-title mb-0">Araçlar</span>
        <button type="button" 
        (click)="newVehicle()"
        class="btn btn-sm btn-outline-secondary py-0">
            <span>Ekle</span>
        </button>
    </div>
    <div class="card-body p-0">
        <div class="vehicle-search-wrapper">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <mat-label>Araç Ara</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Metni giriniz" #input>
            </mat-form-field>
        </div>
        <section class="vehicle-container mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let row; let i=index;"> {{(positionPage)+(i+1)}} </td>
                </ng-container>

                <ng-container matColumnDef="licenseplate">
                    <th mat-header-cell *matHeaderCellDef> Plaka </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.licensePlate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> Marka </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.brand}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef> Araç Yılı </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.year}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="seatingCapacity">
                    <th mat-header-cell *matHeaderCellDef> Kapasite </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.seatingCapacity}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ownerType">
                    <th mat-header-cell *matHeaderCellDef> Sahibi </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.ownerType === 1 ? 'Kiralık':'Şirket'}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> İşlemler </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="btn-group ms-3">
                            <button type="button" 
                            (click)="editVehicle(element)"
                            class="btn btn-sm btn-outline-dark py-0">
                                <span>Düzenle</span>
                            </button>
                            <button type="button" 
                            (click)="deleteVehicle(element)"
                            class="btn btn-sm btn-outline-danger py-0">
                                <span>Sil</span>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"
                    [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                </tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center p-3" [attr.colspan]="displayedColumns.length">Araç yok. <span
                            *ngIf="input.value">"{{input.value}}"</span></td>
                </tr>
            </table>
        </section>
    </div>
</div>