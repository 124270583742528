import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OwnerType, VehicleModel } from 'src/app/models/vehicle';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NewVehicleComponent } from '../new-vehicle/new-vehicle.component';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-vehicle',
  templateUrl: './edit-vehicle.component.html',
  styleUrls: ['./edit-vehicle.component.css']
})
export class EditVehicleComponent implements OnInit{
  
  vehicle: VehicleModel = new VehicleModel();
  public ownerTypeCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: VehicleModel,
  public dialogRef: MatDialogRef<EditVehicleComponent>,
  private vehicleService:VehicleService,
  private toastr: ToastrService){

  }
  
  ngOnInit() {
    //this.vehicle=this.data;
    this.vehicle = Object.assign({}, this.data);
    this.ownerTypeCtrl.setValue(this.vehicle.ownerType.toString());
    console.log(this.vehicle.ownerType.toString())
    console.log(OwnerType.Corporate.toString())
    console.log(OwnerType.Corporate)
  }

  saveVehicle() {
    if (!this.vehicle.licensePlate) {
      this.toastr.error('Plaka bilgisi gereklidir.', 'Araç Bilgileri Düzenleme');
      return;
    }
    if (!this.vehicle.year) {
      this.toastr.error('Yıl bilgisi gereklidir.', 'Araç Bilgileri Düzenleme');
      return;
    }
    if (!this.vehicle.brand) {
      this.toastr.error('Marka bilgisi gereklidir.', 'Araç Bilgileri Düzenleme');
      return;
    }
    
    this.vehicle.ownerType = Number(this.ownerTypeCtrl.value);

    this.vehicleService.put(this.vehicle).subscribe(
      res => {
        this.toastr.success(`Araç Bilgileri bilgileri değiştirildi.`, 'Araç Bilgileri Düzenleme');
        this.dialogRef.close({ success: true, result: this.vehicle });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Araç Bilgileri Düzenleme');
        //this.dialogRef.close({ success: false, result: err });
      }
    ); 
  }
}

