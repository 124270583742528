<mat-card>
    <mat-card-header>
        <mat-card-title>Giriş</mat-card-title>
    </mat-card-header>
    <mat-card-content class="my-4">
        <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Kullanıcı Adı</mat-label>
                    <input type="text" matInput placeholder="Kullanıcı Adı" formControlName="username">
                    <mat-error *ngIf="form.controls['username'].hasError('required')" class="px-0">
                        Kullanıcı adı gerekli
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Şifre</mat-label>
                    <input type="password" matInput placeholder="Şifre" formControlName="password">
                    <mat-error *ngIf="form.controls['password'].hasError('required')" class="px-0">
                        Şifre gerekli
                    </mat-error>
                </mat-form-field>
            </p>

            <div class="button">
                <button type="submit" mat-raised-button>Giriş</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>