import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from './helpers/is-authenticated-guard';
import { AuthorizedLayoutComponent } from './views/shared/layouts/authorizedlayout/authorizedlayout.component';
import { HomelayoutComponent } from './views/shared/layouts/homelayout/homelayout.component';
import { NotFoundComponent } from './views/shared/not-found/not-found.component';
import { HasRoleGuard } from './helpers/has-role.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: HomelayoutComponent,
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'u',
    component: AuthorizedLayoutComponent,
    canActivate: [IsAuthenticatedGuard],
    data: {
      roles: []
    },
    children: [
      {
        path: '', redirectTo: '/u/dashboard', pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [HasRoleGuard],
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'client',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/client-organization/client-organization.module').then(m => m.ClientOrganizationModule)
      },
      {
        path: 'route',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/shuttle-route/shuttle-route.module').then(m => m.ShuttleRouteModule)
      },
      {
        path: 'company',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/server-company/server-company.module').then(m => m.ServerCompanyModule)
      },
      {
        path: 'shuttle',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/shuttle-planning/shuttle-planning.module').then(m => m.ShuttlePlanningModule)
      },
      {
        path: 'user',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'role-permission',
        data: {
          roles: ['SystemAdministrator']
        },
        loadChildren: () => import('./views/role-permission/role-permission.module').then(m => m.RolePermissionModule)
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
