<div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-title mb-0">Sürücüler</span>
        <button type="button" 
        (click)="newDriver()"
        class="btn btn-sm btn-outline-secondary py-0">
            <span>Ekle</span>
        </button>
    </div>
    <div class="card-body p-0">
        <div class="driver-search-wrapper">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <mat-label>Sürücü Ara</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Metni giriniz" #input>
            </mat-form-field>
        </div>
        <section class="driver-container mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let row; let i=index;"> {{(positionPage)+(i+1)}} </td>
                </ng-container>

                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef> İsim </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.firstName}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef> Soyisim </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.lastName}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="phonenumber">
                    <th mat-header-cell *matHeaderCellDef> Telefon </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.phoneNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> İşlemler </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="btn-group ms-3">
                            <button type="button" (click)="editDriver(element)"
                                class="btn btn-sm btn-outline-dark py-0">
                                <span>Düzenle</span>
                            </button>
                            <button type="button" (click)="deleteDriver(element)"
                                class="btn btn-sm btn-outline-danger py-0">
                                <span>Sil</span>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"
                    [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                </tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center p-3" [attr.colspan]="displayedColumns.length">Sürücü yok. <span
                            *ngIf="input.value">"{{input.value}}"</span></td>
                </tr>
            </table>
        </section>
    </div>
</div>