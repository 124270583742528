import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StopModel } from 'src/app/models/stop';
import { StopService } from 'src/app/services/stop.service';

@Component({
  selector: 'app-edit-stop',
  templateUrl: './edit-stop.component.html',
  styleUrls: ['./edit-stop.component.css']
})
export class EditStopComponent implements OnInit{
  stop: StopModel = new StopModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: StopModel,
  public dialogRef: MatDialogRef<EditStopComponent>,
  private stopService:StopService,
  private toastr: ToastrService){

  }

  ngOnInit() {
    //this.vehicle=this.data;
    this.stop = Object.assign({}, this.data);
  }

  saveStop() {
    if (!this.stop.name) {
      this.toastr.error('Durak isim bilgisi gereklidir.', 'Durak Bilgileri Düzenleme');
      return;
    }
        

    this.stopService.put(this.stop).subscribe(
      res => {
        this.toastr.success(`Durak bilgileri değiştirildi.`, 'Durak Bilgileri Düzenleme');
        this.dialogRef.close({ success: true, result: this.stop });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Durak Bilgileri Düzenleme');
        //this.dialogRef.close({ success: false, result: err });
      }
    ); 
  }
}