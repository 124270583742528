import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionModel } from '../models/permission-model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private httpClient: HttpClient,
    private configService: ConfigurationService) { }

  readonly baseURL = this.configService.api_uri + "/identity-api/permissionstore";
  formData: PermissionModel = new PermissionModel();
  list: PermissionModel[];
  selectedParentPermission: PermissionModel = new PermissionModel();

  postPermission(permission:PermissionModel) {
    // this.formData.parentClaimId = this.selectedParentPermission.id;
    // const newPermission = Object.assign({}, this.formData);
    // newPermission.claimType=this.selectedParentPermission.claimType?
    // `${this.selectedParentPermission.claimType}.${this.formData.claimType}`
    // :this.formData.claimType;
    return this.httpClient.post(this.baseURL, permission);
  }

  deletePermission(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  getAll() {
    return this.httpClient.get(`${this.baseURL}`);
  }

  refreshList() {
    this.getAll()
      .toPromise()
      .then(res => {
        this.list = res as PermissionModel[];
        console.log(this.list)
        this.list = this.list.map(item => {
          item.parentClaimType = this.list.find(x => x.id === item.parentClaimId)?.claimType
          return item;
        });
      });
  }
}
