

<h2 mat-dialog-title>Müşteri Bilgileri</h2>

<div mat-dialog-content>
    <div class="container">
        <div class="row">
            <div class="col">
                <form novalidate #form="ngForm" autocomplete="off">
                    <mat-form-field appearance="outline">
                        <mat-label>Müşteri Adı</mat-label>
                        <input matInput placeholder="Müşteri adı..." 
                        name="name" #name="ngModel" [(ngModel)]="client.name" 
                        required [class.invalid]="name.invalid&&name.touched">
                        <!-- <input type="text" class="form-control" name="name" #name="ngModel" [(ngModel)]="data.name" placeholder="Ürün Adı" required [class.invalid]="name.invalid&&name.touched"> -->

                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="dialog-actions">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveNewClient()">Kaydet</button>
        <button mat-button mat-dialog-close class="btn btn-sm btn-dark text-white">Kapat</button>
    </div>
</div> 