import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DriverModel } from '../models/driver';
import { VehicleModel } from '../models/vehicle';
import { VehicleDriverModel } from '../models/vehicle-driver';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleDriverService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/server-company-api/vehicledriver";

  list: VehicleDriverModel[] = [];

  selectedVehicle : VehicleModel | undefined;
  selectedDriver : DriverModel | undefined;

  selectedVehicleIsActive = false;
  selectedDriverIsActive = false;
  
  getAll(companyId:number) {
    return this.httpClient.get(`${this.baseURL}/company/${companyId}`);
  }

  getById(id:number) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }

  refreshList(companyId:number) {
    this.getAll(companyId)
      .toPromise()
      .then(res => this.list = res as VehicleDriverModel[]);
  }

  post(vehicleId:number,driverId:number) {
    let newData = {
      vehicleId: vehicleId,
      driverId: driverId
    };
    console.log(newData)
    return this.httpClient.post(this.baseURL +'/items', newData);
  }

  update(id: number) {
    return this.httpClient.put(`${this.baseURL}/${id}`,{id:id});
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

}
