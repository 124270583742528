import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionService } from './permission-service.service';
import { ConfigurationService } from './configuration.service';
import { RoleModel } from '../models/role-model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient,
    private configurationService: ConfigurationService,
    private permissionService: PermissionService) { }

  readonly baseURL = this.configurationService.api_uri + "/identity-api/role";
  formData: RoleModel = new RoleModel();
  list: RoleModel[];
  selectedRole: RoleModel;

  postRole(role: RoleModel) {
    return this.httpClient.post(this.baseURL, role);
  }

  putRole(role: RoleModel) {
    console.log(this.formData)
    return this.httpClient.put(`${this.baseURL}/${role.id}`, role);
  }

  addPermission(id: number) {
    return this.httpClient.put(`${this.baseURL}/addPermission`, {
      roleId: this.selectedRole.id,
      permissionStoreId: id
    });
  }
  removePermission(id: number) {
    return this.httpClient.put(`${this.baseURL}/removePermission`, {
      roleId: this.selectedRole.id,
      permissionStoreId: id
    });
  }



  deleteRole(id: string) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  getAll() {
    return this.httpClient.get(`${this.baseURL}`);
  }

  getById(roleId: string) {
    return this.httpClient.get(`${this.baseURL}/${roleId}`)
  }

  refreshList() {
    this.getAll()
      .toPromise()
      .then(res => this.list = res as RoleModel[]);
  }

  selectRole(selectedRole: RoleModel) {
    this.httpClient.get(`${this.baseURL}/${selectedRole.id}`)
      .toPromise()
      .then(res => {
        this.selectedRole = res as RoleModel;

        this.refreshPermissionListForSelectedRole();
      });
  }

  refreshPermissionListForSelectedRole() {
    const permissionArray = this.selectedRole.permissionStoreViews.map((permissionModel) => {
      return permissionModel.claimType;
    });
    this.permissionService.list = this.permissionService.list.map(item => {
      item.isSelected = permissionArray.includes(item.claimType);
      return item;
    });
  }
}
