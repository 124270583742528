import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VehicleModel } from '../models/vehicle';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private configurationService: ConfigurationService,
    private httpClient: HttpClient) { }

  readonly baseURL = this.configurationService.api_uri + "/server-company-api/vehicle";

  list: VehicleModel[] = [];

  getAll(companyId:number) {
    return this.httpClient.get(`${this.baseURL}/company/${companyId}`);
  }

  getById(id:number) {
    return this.httpClient.get(`${this.baseURL}/id/${id}`);
  }

  getCounts(companyId:number) {
    return this.httpClient.get(`${this.baseURL}/vehiclecount/company/${companyId}`);
  }

  refreshList(companyId:number) {
    this.getAll(companyId)
      .toPromise()
      .then(res => this.list = res as VehicleModel[]);
  }

  post(data: VehicleModel) {
    return this.httpClient.post(`${this.baseURL}/items`, data);
  }

  put(data: VehicleModel) {
    return this.httpClient.put(`${this.baseURL}/${data.id}`, data);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

}
