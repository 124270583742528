<main class="d-flex" id="wrapper">

  <div class="p-3 bg-light border-end sidebar" id="sidebar-wrapper" [ngClass]="status ? 'hide' : 'show'">
    <a routerLink="/u/dashboard/overview" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
      <span class="material-icons">business</span>
      <span class="ms-2 fs-5 fw-semibold">
        {{authService.autorizedUser.companyname}}
      </span>
    </a>
    <ul class="list-unstyled ps-0">
      <!-- <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false">
          Home
        </button>
        <div class="collapse" id="home-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Overview</a></li>
            <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Updates</a></li>
            <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Reports</a></li>
          </ul>
        </div>
      </li> -->
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="true">
          Gösterge Paneli
        </button>
        <div class="collapse show" id="dashboard-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a routerLink="/u/dashboard/overview" class="link-dark d-inline-flex text-decoration-none rounded">Genel Bakış</a>
            </li>
            <li><a routerLink="/u/dashboard/client" class="link-dark d-inline-flex text-decoration-none rounded">Müşteriler</a></li>
            <li><a routerLink="/u/dashboard/shuttle" class="link-dark d-inline-flex text-decoration-none rounded">Araçlar</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#vehicles-collapse" aria-expanded="false">
          Araçlar
        </button>
        <div class="collapse" id="vehicles-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <a routerLink="/u/company/vehicle-driver"
                class="link-dark d-inline-flex text-decoration-none rounded">Araçlar ve Sürücüler</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#clients-collapse" aria-expanded="false">
          Müşteri
        </button>
        <div class="collapse" id="clients-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a routerLink="/u/client/list"
                class="link-dark d-inline-flex text-decoration-none rounded">Müşteriler</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#planning-collapse" aria-expanded="false">
          Planlama
        </button>
        <div class="collapse" id="planning-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li>
              <a routerLink="/u/shuttle"
                class="link-dark d-inline-flex text-decoration-none rounded">Seferler</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="border-top my-3"></li>
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#user-collapse" aria-expanded="false">
          Kullanıcı Yönetimi
        </button>
        <div class="collapse" id="user-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a routerLink="/u/user/list" class="link-dark d-inline-flex text-decoration-none rounded">Kullanıcılar</a></li> 
            <li><a routerLink="/u/role-permission" class="link-dark d-inline-flex text-decoration-none rounded">Yetki İzinleri</a></li>
            <!-- <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Settings</a></li>
            <li><a routerLink="/auth/logout" class="link-dark d-inline-flex text-decoration-none rounded">Çıkış</a></li> -->
          </ul>
        </div>
      </li>
      <li class="border-top my-3"></li>
      <li class="mb-1">
        <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
          data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
          Hesap <small class="ms-2 text-muted">({{authService.autorizedUser.username}})</small>
        </button>
        <div class="collapse" id="account-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <!-- <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">New...</a></li> -->
            <!-- <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Profile<small
                  class="ms-2 text-muted">({{authService.autorizedUser.email}})</small></a></li> -->
            <!-- <li><a routerLink="#" class="link-dark d-inline-flex text-decoration-none rounded">Settings</a></li> -->
            <li><a routerLink="/auth/logout" class="link-dark d-inline-flex text-decoration-none rounded">Çıkış</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </div>


  <div id="page-content-wrapper" class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light+ border-bottom">

      <div class="container-fluid">
        <button class="btn btn-outline-dark btn-sm d-flex justify-content-between align-items-center"
          (click)="clickEvent()">
          <span class='material-icons'>menu</span>
        </button>
        <div> {{authService.autorizedUser.companyname}} </div>
      </div>
    </nav>

    <router-outlet></router-outlet>
  </div>

</main>