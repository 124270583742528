declare global{
    interface String {
        trToUpper() : string;
        trToLower() : string;
        trToEn() : string;
        removeSpecialCharacters() : string;
        toDate() : Date;
        toTimeTick():number;
    }
    
    interface Date{
        resetTimeZoneOffset() : string;
        toUTCDate() : Date;
        addDay(value:number) : Date;
        toDateOnly():Date;
    }
}
String.prototype.trToUpper = function (this: string) {
  var string = this;
  var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
  for (var key in letters) {
    string = string.replace(new RegExp('[' + key + ']', 'g'), letters[key]);
  }
  //string = string.replace(/(([iışğüçö]))+/g, function (letter) { return letters[letter]; })
  return string.toUpperCase();
}

String.prototype.trToLower = function (this: string) {
  var string = this;
  var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
  for (var key in letters) {
    string = string.replace(new RegExp('[' + key + ']', 'g'), letters[key]);
  }
  //string = string.replace(/(([İIŞĞÜÇÖ]))+/g, function (letter) { return letters[letter]; })
  return string.toLowerCase();
}

String.prototype.trToEn = function (this: string) {
  var string = this;

  const letters = {
    'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I', 'ç': 'c', 'Ç': 'C', 'ü': 'u',
    'Ü': 'U', 'ö': 'o', 'Ö': 'O', 'ğ': 'g', 'Ğ': 'G'
  };

  for (var key in letters) {
    string = string.replace(new RegExp('[' + key + ']', 'g'), letters[key]);
  }
  return string;

}

String.prototype.removeSpecialCharacters = function (this: string) {
  var string = this;
  return string.replace(/[^\w\s]/gi, '')
}

String.prototype.toDate = function (this: string) {
  var string = this;
  return new Date(string)
}

// Expects 00:00 formats
String.prototype.toTimeTick = function (this: string) {
    var number = Number(this.slice(0,2))*(60*60*1000*10000)+Number(this.slice(-2))*(1*60*1000*10000);
    return number;
  }


Date.prototype.resetTimeZoneOffset = function (this: Date) {
  let copyOf = new Date(this.valueOf())
  var timeZoneDifference = (copyOf.getTimezoneOffset() / 60) * -1; //convert to positive value.
  copyOf.setTime(copyOf.getTime() + (timeZoneDifference * 60) * 60 * 1000);
  return copyOf.toISOString();
}

Date.prototype.toUTCDate = function (this: Date) {
  let copyOf = new Date(this.valueOf())

  let year = copyOf.getUTCFullYear(),
    month = copyOf.getUTCMonth(),
    day = copyOf.getUTCDate(),
    hour = copyOf.getUTCHours(),
    second = copyOf.getUTCSeconds(),
    millisecond = copyOf.getUTCMilliseconds();

  var utc = new Date(year, month, day, hour, second, millisecond);
  return utc;
}

Date.prototype.addDay = function (this: Date,value:number) {
  let copyOf = new Date(this.valueOf())

  copyOf.setDate(copyOf.getDate()+value);

  return copyOf;
}

Date.prototype.toDateOnly = function (this: Date) {
  let copyOf = new Date(this.valueOf())

  let year = copyOf.getFullYear(),
    month = copyOf.getMonth(),
    day = copyOf.getDate();

  var date = new Date(year, month, day);
  return date;
}

export { }
