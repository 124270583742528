import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RouteModel } from 'src/app/models/route';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-new-route',
  templateUrl: './new-route.component.html',
  styleUrls: ['./new-route.component.css']
})
export class NewRouteComponent implements OnInit {

  newRoute: RouteModel = new RouteModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: number,
    public dialogRef: MatDialogRef<NewRouteComponent>,
    private routeService: RouteService,
    private toastr: ToastrService) {

  }

  ngOnInit() {
  }

  saveNewRoute() {
    if (!this.newRoute.name) {
      this.toastr.error('Güzergah isim bilgisi gereklidir.', 'Güzergah Ekleme');
      return;
    }

    this.newRoute.clientOrganizationId=this.data;
    
    this.routeService.post(this.newRoute).subscribe(
      res => {
        this.toastr.success(`Güzergah eklendi.`, 'Güzergah Ekleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        console.log(err.error);
        let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' '):err.error : Object.values(err.errors).join(' ');
          this.toastr.error(errors, 'Güzergah Ekleme');
        // if (err.status===400) {
        //   this.toastr.error("Güzergah eklenemedi. Daha sonra tekrar deneyiniz.", 'Güzergah Ekleme');
        // }
        // else{
        //   let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        //   this.toastr.error(errors, 'Güzergah Ekleme');
        //   //this.dialogRef.close({ success: false, result: err });
        // }
      }
    ); 
  }

}
