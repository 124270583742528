import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { StorageService } from './storage.service';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn = this._isLoggedIn.asObservable();

  autorizedUser: any;
  autorizedUserDetail:any;

  constructor(private storageService: StorageService,
    private configurationService: ConfigurationService,
    public httpClient: HttpClient,
    private router:Router) {

    this.checkAutorization();

  }

  checkAutorization() {
    let token = this.storageService.getToken('auth-token');

    this._isLoggedIn.next(!!token);
    if (!!token) {
      this.autorizedUser = this.parseUser(token);
    }
  }

  readonly baseURL = this.configurationService.api_uri + "/identity-api";

  login(data: any) {
    return this.httpClient.post(`${this.baseURL}/auth/login`, data).pipe(
      tap(
        (response: any) => {
          if (!response.token)
            throw response;

          this.storageService.saveToken(response.token, 'auth-token');
          this.checkAutorization();
          //this.getUserDetail()
        },
        (error) => {
          throw error
        })
    );
  }

  logout() {
    this.storageService.removeAll();
    this.router.navigate(['/']);
  }

  public hasRole(role: string): boolean {
    return this.autorizedUser?.role.includes(role);
  }

  public IsLoggedIn(): boolean {
    return this._isLoggedIn.value;
  }

  // getUserDetail(){
  //   return this.httpClient.get(`${this.baseURL}/user/${this.autorizedUser.username}`)
  //   .toPromise()
  //   .then(res => {
  //     console.log(res)
  //     this.autorizedUserDetail=res;
  //   });
  // }

  parseUser(token: string): any {

    const base64String = token.split('.')[1];
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    let tokenValue = atob(base64);
    return JSON.parse(this.decodeUnicode(tokenValue)) as any;
  }

  decodeUnicode(tokenValue: string): string {
    return decodeURIComponent(tokenValue.split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
