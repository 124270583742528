import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, tap } from 'rxjs';
import { VehicleDriverModel } from 'src/app/models/vehicle-driver';
import { AuthService } from 'src/app/services/auth.service';
import { VehicleDriverService } from 'src/app/services/vehicle-driver.service';

@Component({
  selector: 'app-vehicle-driver',
  templateUrl: './vehicle-driver.component.html',
  styleUrls: ['./vehicle-driver.component.css'],
  providers: [DatePipe, CurrencyPipe, DecimalPipe]
})
export class VehicleDriverComponent implements OnInit {
  displayedColumns: string[] = ['position', 'fullname', 'licenseplate', 'startdate', 'actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  positionPage: number = 0;

  keepPrevious = false;


  constructor(public vehicleDriverService: VehicleDriverService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
    this.loadVechileDrivers();
  }

  loadVechileDrivers() {
    let companyId = this.authService.autorizedUser.company;
    this.vehicleDriverService.getAll(companyId)
      .toPromise()
      .then(res => {
        console.log(res)
        this.vehicleDriverService.list = res as VehicleDriverModel[];
        this.vehicleDriverService.list=this.vehicleDriverService.list.filter(x=>x.isActive)
        this.dataSource = new MatTableDataSource(this.vehicleDriverService.list);
      });
  }


  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
  applyFilter(event: Event) {

    setTimeout(() => {
      const filterValue = (event.target as HTMLInputElement).value;

      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };

      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }, 500);
  }

  saveVehicleDriver() {
    if (!this.vehicleDriverService.selectedVehicle) {
      this.toastr.error('Araş seçmelisiniz.', 'Araç-Sürücü Ataması');
      return;
    }
    if (!this.vehicleDriverService.selectedDriver) {
      this.toastr.error('Sürücü seçmelisiniz.', 'Araç-Sürücü Ataması');
      return;
    }

    this.vehicleDriverService.post(this.vehicleDriverService.selectedVehicle.id,
      this.vehicleDriverService.selectedDriver.id).subscribe(
        res => {
          this.loadVechileDrivers();

          let text = this.vehicleDriverService.selectedVehicle?.licensePlate + ' - ' +
            this.vehicleDriverService.selectedDriver?.firstName + ' '
          this.vehicleDriverService.selectedDriver?.lastName;
          this.toastr.success(`${text} tamamlandı.`, 'Araç-Sürücü Ataması');
        },
        err => {
          console.log(err);
          let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
          this.toastr.error(errors, 'Araç-Sürücü Ataması');
        }
      );
  }

  cancelVehicleDriver(element: VehicleDriverModel) {
    this.vehicleDriverService.update(element.id).subscribe(
      res => {
        this.vehicleDriverService.list = this.vehicleDriverService.list.filter(x => x.id != element.id);
        this.dataSource = new MatTableDataSource(this.vehicleDriverService.list);
        let text = element.vehicle.licensePlate + ' - ' +
          element.driver.firstName + ' '
        element.driver.lastName;
        this.toastr.success(`${text} iptal edildi.`, 'Araç-Sürücü Ataması');
      },
      err => {
        let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' ') : "Beklenmedik Hata!" : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Araç-Sürücü Ataması');
      }
    );
  }
  deleteVehicleDriver(element: VehicleDriverModel) {
    let options = {
      title: `Araç-Sürücü atamasını istediğinizden emin misiniz?`,
      confirmLabel: 'Evet',
      declineLabel: 'Hayır'
    };
    this.ngxBootstrapConfirmService.confirm(options)
      .then((confirmRes: boolean) => {
        if (confirmRes) {

          this.vehicleDriverService.delete(element.id).subscribe(
            res => {
              this.vehicleDriverService.list = this.vehicleDriverService.list.filter(x => x.id != element.id);
              this.dataSource = new MatTableDataSource(this.vehicleDriverService.list);
              let text = element.vehicle.licensePlate + ' - ' +
                element.driver.firstName + ' '
              element.driver.lastName;
              this.toastr.success(`${text} silindi.`, 'Araç-Sürücü Ataması');
            },
            err => {
              let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' ') : "Beklenmedik Hata!" : Object.values(err.errors).join(' ');
              this.toastr.error(errors, 'Araç-Sürücü Ataması');
            }
          );
        }
      });
  }
}
