

<h2 mat-dialog-title>Yeni Güzergah</h2>

<div mat-dialog-content>
    <div class="container">
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="row mb-1">
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Güzergah İsmi</mat-label>
                        <input matInput placeholder="Güzergah ismini giriniz" 
                        name="name" #name="ngModel" [(ngModel)]="newRoute.name" 
                        required [class.invalid]="name.invalid&&name.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field  subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Açıklama</mat-label>
                        <input matInput placeholder="Açıklama giriniz" 
                        name="description" #description="ngModel" [(ngModel)]="newRoute.description" 
                        [class.invalid]="description.invalid&&description.touched">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <div class="dialog-actions">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveNewRoute()">Kaydet</button>
        <button mat-button mat-dialog-close class="btn btn-sm btn-dark text-white">Kapat</button>
    </div>
</div> 