<div class="row">
    <div class="col-6 pe-1 pb-2">
        <app-vehicle></app-vehicle>
    </div>
    <div class="col-6 ps-1 pb-2">
        <app-driver></app-driver>
    </div>
    <div class="col-12 pb-2">
        <div class="card">
            <div class="card-header text-center">
                <span class="card-title">Araç-Sürücü Ataması</span>
            </div>
            <div class="card-body">
                <section class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="mb-0">
                            <span
                                *ngIf="vehicleDriverService.selectedVehicle">{{vehicleDriverService.selectedVehicle.licensePlate}}
                                <span *ngIf="vehicleDriverService.selectedVehicleIsActive" class="text-danger">*</span>
                            </span>

                            <span class="text-muted" *ngIf="!vehicleDriverService.selectedVehicle">[Araç
                                Seçiniz]</span>
                        </p>
                        <span class="mx-3">-</span>
                        <p class="mb-0">
                            <span
                                *ngIf="vehicleDriverService.selectedDriver">{{vehicleDriverService.selectedDriver.firstName}}
                                {{vehicleDriverService.selectedDriver.lastName}}
                                <span *ngIf="vehicleDriverService.selectedDriverIsActive" class="text-danger">*</span>
                            </span>
                            <span class="text-muted" *ngIf="!vehicleDriverService.selectedDriver">[Sürücü
                                Seçiniz]</span>
                        </p>
                        <p class="mb-0 ms-5">
                            <span *ngIf="(vehicleDriverService.selectedVehicleIsActive && vehicleDriverService.selectedVehicle)" class="text-muted">Aracın sürücüsü zaten var.</span>
                            <span *ngIf="(vehicleDriverService.selectedDriverIsActive && vehicleDriverService.selectedDriver)" class="ms-1 text-muted">Sürücünün aracı zaten var.</span>
                        </p>
                    </div>
                    <div>
                        <!-- <mat-checkbox color="primary" [(ngModel)]="keepPrevious" labelPosition="before">
                            Aktif olanı koru
                        </mat-checkbox> -->
                        <div class="btn-group ms-3">
                            <button type="button"
                                [disabled]="(!vehicleDriverService.selectedVehicle || !vehicleDriverService.selectedDriver)"
                                class="btn btn-sm btn-outline-secondary" (click)="saveVehicleDriver()">
                                <i class="mdi mdi-plus me-1"></i>
                                <span>Onayla</span>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">Aktif Araç-Sürücü Listesi ({{dataSource?.data?.length}})</span>
                <button type="button" (click)="loadVechileDrivers()" class="btn btn-sm btn-outline-dark py-0">
                    <span>Yenile</span>
                </button>
            </div>
            <div class="card-body p-0">
                <div class="vehicle-driver-search-wrapper">
                    <mat-form-field appearance="fill" subscriptSizing="dynamic">
                        <mat-label>Araç-Sürücü Ara</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Metni giriniz" #input>
                    </mat-form-field>
                </div>
                <section class="vehicle-driver-container mat-elevation-z8" tabindex="0">

                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let row; let i=index;"> {{(positionPage)+(i+1)}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fullname">
                            <th mat-header-cell *matHeaderCellDef> İsim </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.driver.firstName}}</span>
                                <span class="ms-1">{{element.driver.lastName}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="licenseplate">
                            <th mat-header-cell *matHeaderCellDef> Plaka </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.vehicle.licensePlate}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="startdate">
                            <th mat-header-cell *matHeaderCellDef> Atama Tarihi </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.startDate |date:'dd/MM/yyyy'}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> İşlemler </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="btn-group ms-3">
                                    <button type="button" [disabled]="false" (click)="cancelVehicleDriver(element)"
                                        class="btn btn-sm btn-outline-dark py-0">
                                        <span>İptal</span>
                                    </button>
                                    <!-- <button type="button" [disabled]="false" class="btn btn-sm btn-outline-danger py-0"
                                        (click)="deleteVehicleDriver(element)">
                                        <span>Sil*</span>
                                    </button> -->
                                </div>
                                <!-- <span class="text-danger"> * : only sysadmin</span> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center p-3" [attr.colspan]="displayedColumns.length">Araç-Sürücü
                                ataması yok.
                                <span *ngIf="input.value">"{{input.value}}"</span>
                            </td>
                        </tr>
                    </table>
                </section>
            </div>
        </div>
    </div>
</div>