import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, pluck } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { VehicleDriverService } from 'src/app/services/vehicle-driver.service';
import { DriverService } from 'src/app/services/driver.service';
import { DriverModel } from 'src/app/models/driver';
import { EditDriverComponent } from './edit-driver/edit-driver.component';
import { NewDriverComponent } from './new-driver/new-driver.component';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {
  displayedColumns: string[] = ['position', 'firstname', 'lastname', 'phonenumber','actions'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  positionPage: number = 0;
  selection = new SelectionModel<any>(false, []);
  
  constructor(public driverService: DriverService,
    public vehicleDriverService: VehicleDriverService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
    let companyId = this.authService.autorizedUser.company;
    this.driverService.getAll(companyId)
      .toPromise()
      .then(res => {
        console.log(res)
        this.driverService.list = res as DriverModel[];
        this.dataSource = new MatTableDataSource(this.driverService.list);
      });

    this.selection.changed
      .pipe(pluck('source', 'selected'))
      .subscribe((selectedList) => {
        console.log('selected driver')
        console.log(selectedList)
        this.vehicleDriverService.selectedDriver=this.selection.selected[0];
        if(this.selection.selected.length>0){
          this.vehicleDriverService.selectedDriverIsActive=this.vehicleDriverService.list
          .some(x=>x.driver.id==this.selection.selected[0].id)
        }
      });
  }

  newDriver(){
    let dialogRef=this.dialog.open(NewDriverComponent, {
      data: {},
      minWidth:'50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`); 
      console.log(result); 
      if (result.success) {
        this.driverService.list.push(result.result);
        this.dataSource = new MatTableDataSource(this.driverService.list);
      }
    });
  }

  editDriver(element:DriverModel){
    let dialogRef=this.dialog.open(EditDriverComponent, {
      data: element,
      minWidth:'50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`); 
      console.log(result); 
      if (result.success) {
        let id=result.result.id;
        let curr=this.driverService.list.find(x=>x.id==id);
        curr.firstName=result.result.firstName;
        curr.lastName=result.result.lastName;
        curr.phoneNumber=result.result.phoneNumber;
        curr.address=result.result.address;
      }
    });
  }

  deleteDriver(element:DriverModel){
    let options = {
      title: `Sürücüyü istediğinizden emin misiniz?`,
      confirmLabel: 'Evet',
      declineLabel: 'Hayır'
    };
    this.ngxBootstrapConfirmService.confirm(options)
      .then((confirmRes: boolean) => {
        if (confirmRes) {

          this.driverService.delete(element.id).subscribe(
            res => {
              this.driverService.list=this.driverService.list.filter(x=>x.id != element.id);
              this.dataSource = new MatTableDataSource(this.driverService.list);
              
              let text =this.vehicleDriverService.selectedDriver?.firstName + ' '
            this.vehicleDriverService.selectedDriver?.lastName;
            
            this.toastr.success(`${text} isimli sürücü silindi.`, 'Sürücü Silme');
            },
            err => {
              let errors = err.error ? err.error.errors ? Object.values(err.error.errors).join(' ') : "Beklenmedik Hata!" : Object.values(err.errors).join(' ');
              this.toastr.error(errors, 'Sürücü Silme');
            }
          );
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}