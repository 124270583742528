<div class="row">
    <!-- column -->
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span class="card-title mb-0">Kullanıcı Listesi</span>
                <button type="button" (click)="openNewUserDialog()" class="btn btn-sm btn-outline-secondary py-0">
                    <span>Yeni Kullanıcı</span>
                </button>
            </div>
            <div class="card-body p-0">
                <div class="user-search">
                    <mat-form-field class="user-search-wrapper" appearance="fill" subscriptSizing="dynamic">
                        <mat-label>Kullanıcı Ara</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Kullanıcı adı..." #input>
                    </mat-form-field>
                </div>
                <section class="example-container mat-elevation-z8" tabindex="0">

                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="userName"
                        matSortDisableClear matSortDirection="asc">

                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef class="py-2"> # </th>
                            <td mat-cell *matCellDef="let row; let i=index;" class="py-2"> {{(positionPage)+(i+1)}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="editusereaction">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <div class="btn-group">
                                    <button (click)="openEditUserDialog(element)" type="button"
                                        class="btn btn-sm btn-outline-dark" style="width: 30px; padding: 2px 4px;">
                                        <span class="material-icons">edit</span>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Kullanıcı Adı </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.userName}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> İsim </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.fullName}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> E-Posta </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.email}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Şirket </th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.companyName}} </span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center p-3" [attr.colspan]="displayedColumns.length">Müşteri yok.
                                <span *ngIf="input.value">"{{input.value}}"</span></td>
                        </tr>
                    </table>
                </section>
            </div>
        </div>
    </div>
</div>