export class VehicleModel {
    id: number;
    licensePlate: string;
    brand: string;
    model: string;
    year: number;
    seatingCapacity: number;
    companyId: number;
    companyName: string;
    ownerType: OwnerType;
}

export enum OwnerType {
    Corporate,
    Franchise
}

export class VehicleCountsModel {
    total: number;
    capacity: number;
    passiveVehicle: number;
}