import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './views/auth/auth.component';
import { LoginComponent } from './views/auth/login/login.component';
import { HomelayoutComponent } from './views/shared/layouts/homelayout/homelayout.component';
import { AuthorizedLayoutComponent } from './views/shared/layouts/authorizedlayout/authorizedlayout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { ClientOrganizationComponent } from './views/client-organization/client-organization.component';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SpinnerComponent } from './views/shared/spinner.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { NotFoundComponent } from './views/shared/not-found/not-found.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import { ServerCompanyComponent } from './views/server-company/server-company.component';
import { VehicleDriverComponent } from './views/server-company/vehicle-driver/vehicle-driver.component';
import { VehicleComponent } from './views/server-company/vehicle-driver/vehicle/vehicle.component';
import { DriverComponent } from './views/server-company/vehicle-driver/driver/driver.component';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import {MatTabsModule} from '@angular/material/tabs';
import { ShuttleRouteComponent } from './views/shuttle-route/shuttle-route.component';
import { RouteComponent } from './views/shuttle-route/route/route.component';
import { StopComponent } from './views/shuttle-route/stop/stop.component';
import { NewRouteComponent } from './views/shuttle-route/route/new-route/new-route.component';
import { EditRouteComponent } from './views/shuttle-route/route/edit-route/edit-route.component';
import { NewStopComponent } from './views/shuttle-route/stop/new-stop/new-stop.component';
import { EditStopComponent } from './views/shuttle-route/stop/edit-stop/edit-stop.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CustomPaginatorConfiguration } from './helpers/customPaginatorConfiguration';
import { ShuttlePlanningComponent } from './views/shuttle-planning/shuttle-planning.component';
import { UserComponent } from './views/user/user.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    HomelayoutComponent,
    AuthorizedLayoutComponent,
    DashboardComponent,
    ClientOrganizationComponent,
    SpinnerComponent,
    NotFoundComponent,
    ServerCompanyComponent,
    VehicleDriverComponent,
    VehicleComponent,
    DriverComponent,
    ShuttleRouteComponent,
    ShuttlePlanningComponent,
    RouteComponent,
    StopComponent,
    NewRouteComponent,
    EditRouteComponent,
    NewStopComponent,
    EditStopComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    NgbModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center', timeOut: 5000,
      preventDuplicates: false,
      closeButton: true,
      progressBar: true,
      maxOpened: 0,
      autoDismiss: true,
      enableHtml: true
    }),
    HttpClientModule,
    MatIconModule,
    NgxBootstrapConfirmModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule
  ],
  providers: [
    DatePipe,
    authInterceptorProviders,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    // { provide: DEFAULT_CURRENCY_CODE, useValue: '₺' },
    // { provide: MAT_DATE_LOCALE, useValue: 'tr-TR' },
    // { provide: LOCALE_ID, useValue: 'tr-TR' },
    { provide: MatPaginatorIntl, useValue: CustomPaginatorConfiguration() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
