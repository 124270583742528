import { Component } from '@angular/core';

@Component({
  selector: 'app-shuttle-planning',
  templateUrl: './shuttle-planning.component.html',
  styleUrls: ['./shuttle-planning.component.css']
})
export class ShuttlePlanningComponent {

}
