import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { NewUserComponent } from './new-user/new-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['position','editusereaction', 'userName','fullName','email','companyName'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  positionPage: number = 0;
  
  constructor(public userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    let companyId=this.authService.autorizedUser.company;
    this.userService.getAll()
      .toPromise()
      .then(res => {
        console.log(res)
        this.userService.list = res as UserModel[];
        this.dataSource = new MatTableDataSource(this.userService.list);

        this.dataSource.sort = this.sort;
      });
  }

  openNewUserDialog() {
    let dialogRef=this.dialog.open(NewUserComponent, {
      data: {},
      minWidth:'50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`); 
      console.log(result); 
      if (result?.success) {
        this.userService.list.push(result.result);
        this.dataSource = new MatTableDataSource(this.userService.list);
      }
    });
  }

  openEditUserDialog(user:UserModel) {
    let dialogRef=this.dialog.open(EditUserComponent, {
      data: user,
      minWidth:'50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result`); 
      console.log(result); 
      if (result?.success) {
        let id = result.result.id;
        let curr = this.userService.list.find(x => x.id == id);
        curr.userName = result.result.userName;
        curr.firstName = result.result.firstName;
        curr.lastName = result.result.lastName;
        curr.email = result.result.email;
        curr.phoneNumber = result.result.phoneNumber;
        curr.roleId = result.result.roleId;
        curr.companyId = result.result.companyId;
        curr.companyName = result.result.companyName;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
