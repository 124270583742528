import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RouteModel } from 'src/app/models/route';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-edit-route',
  templateUrl: './edit-route.component.html',
  styleUrls: ['./edit-route.component.css']
})
export class EditRouteComponent implements OnInit{
  route: RouteModel = new RouteModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: RouteModel,
  public dialogRef: MatDialogRef<EditRouteComponent>,
  private routeService:RouteService,
  private toastr: ToastrService){

  }

  ngOnInit() {
    //this.vehicle=this.data;
    this.route = Object.assign({}, this.data);
  }

  saveRoute() {
    if (!this.route.name) {
      this.toastr.error('Güzergah isim bilgisi gereklidir.', 'Güzergah Bilgileri Düzenleme');
      return;
    }
        

    this.routeService.put(this.route).subscribe(
      res => {
        this.toastr.success(`Güzergah bilgileri değiştirildi.`, 'Güzergah Bilgileri Düzenleme');
        this.dialogRef.close({ success: true, result: this.route });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Güzergah Bilgileri Düzenleme');
        //this.dialogRef.close({ success: false, result: err });
      }
    ); 
  }
}
