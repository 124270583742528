import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getToken('auth-token');
    let authReq = token ? req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) }) :
      req.clone();
    return next.handle(authReq)
      .pipe(
        catchError((error) => {
          let handled: boolean = false;
          //console.error(error);
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error(`Error Event`);
            } else {
              console.error(error);
              let errorMessage = ''
              switch (error.status) {
                case 0:      //login
                  errorMessage = 'Beklenmedik bir hata oluştu.';
                  this.toastr.error(errorMessage, 'Hata');
                  handled = true;
                  break;
                case 400:     //bad request
                  // errorMessage = error.error;
                  // this.toastr.error(errorMessage, 'Hata - 400');
                  // handled = true;
                  break;
                case 401:      //login
                  this.authService.logout();
                  this.router.navigateByUrl("/login");
                  //console.log(`redirect to login`);
                  handled = true;
                  break;
                case 403:     //forbidden
                  errorMessage = 'İşlem için yetkiniz bulunmamaktadır.';
                  this.toastr.error(errorMessage, 'Hata - 403');
                  handled = true;
                  break;
                case 404:     //notfound
                  errorMessage = error.error;
                  this.toastr.error(errorMessage, 'Hata - 404');
                  //this.router.navigateByUrl("/not-found");
                  handled = true;
                  break;
                // case 500:
                //   errorMessage = 'Beklenmedik bir hata oluştu.';
                //   this.toastr.error(errorMessage, 'Hata');
                //   handled = true;
                //   break;
              }
            }
          }
          else {
            this.toastr.error('Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.', 'Hata');
          }

          if (handled) {
            //console.log('return back ');
            return of(error);
          } else {
            //console.log('throw error back to to the subscriber');
            return throwError(error);
          }
        })
      )
  }
}

export const authInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];