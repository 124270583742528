import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  
  env_title=environment.title;
  api_uri = environment.apiURL;
  signalrHub_uri=environment.signalrHubUrl;
  constructor() { 
    console.log('environment: '+this.env_title);
  }
}
