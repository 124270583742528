export class UserModel {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    roleId: string;
    companyId: number;
    companyName: string
}
