<h2 mat-dialog-title>Yeni Araç</h2>

<div mat-dialog-content>
    <div class="container">
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="row mb-2 text-center">
                <div class="col">
                    <mat-button-toggle-group [formControl]="ownerTypeCtrl" aria-label="Font Style">
                        <mat-button-toggle checked value=0>Şirket</mat-button-toggle>
                        <mat-button-toggle value=1>Kiralık</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Plaka</mat-label>
                        <input matInput placeholder="Plakayı giriniz" name="licensePlate" #licensePlate="ngModel"
                            [(ngModel)]="newVehicle.licensePlate" required
                            [class.invalid]="licensePlate.invalid&&licensePlate.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Yıl</mat-label>
                        <input matInput placeholder="Yılı giriniz" name="year" #year="ngModel"
                            [(ngModel)]="newVehicle.year" required [class.invalid]="year.invalid&&year.touched">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Marka</mat-label>
                        <input matInput placeholder="Markayı giriniz" name="brand" #brand="ngModel"
                            [(ngModel)]="newVehicle.brand" required [class.invalid]="brand.invalid&&brand.touched">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Model</mat-label>
                        <input matInput placeholder="Modeli giriniz" name="model" #model="ngModel"
                            [(ngModel)]="newVehicle.model" [class.invalid]="model.invalid&&model.touched">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <mat-label>Koltuk Sayısı</mat-label>
                        <input matInput placeholder="Koltuk sayısını giriniz" name="seatingCapacity"
                            #seatingCapacity="ngModel" [(ngModel)]="newVehicle.seatingCapacity"
                            [class.invalid]="seatingCapacity.invalid&&seatingCapacity.touched">
                    </mat-form-field>
                </div>
                <div class="col">

                </div>
            </div>
        </form>

    </div>
</div>
<div mat-dialog-actions>
    <div class="dialog-actions">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="saveNewVehicle()">Kaydet</button>
        <button mat-button mat-dialog-close class="btn btn-sm btn-dark text-white">Kapat</button>
    </div>
</div>