import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientOrganizationService } from 'src/app/services/client-organization.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-new-client-organization',
  templateUrl: './new-client-organization.component.html',
  styleUrls: ['./new-client-organization.component.css']
})
export class NewClientOrganizationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewClientOrganizationComponent>,
    private authService: AuthService,
    public clientOrganizationService: ClientOrganizationService,
    private toastr: ToastrService) { }

  client = {
    name: "",
    userName: ""
  };

  ngOnInit(): void {
    this.client.userName = this.authService.autorizedUser.username;
  }

  saveNewClient() {
    console.log(this.client)
    if (!this.client?.name) {
      this.toastr.error('Müşteri ismi gereklidir.', 'Müşteri Ekleme');
      return;
    }

    this.clientOrganizationService.post(this.client).subscribe(
      res => {
        this.toastr.success(`${this.client.name} eklendi.`, 'Müşteri Ekleme');
        this.dialogRef.close({ success: true, result: res });
      },
      err => {
        console.log(err);
        let errors = err.error ? Object.values(err.error.errors).join(' ') : Object.values(err.errors).join(' ');
        this.toastr.error(errors, 'Müşteri Ekleme');
        this.dialogRef.close({ success: false, result: err });
      }
    );
  }
}
